// src/store.js
import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import { persistStore, persistReducer } from 'redux-persist';
import detailPageSlice from '../features/fenzacciDetailPage';
import productVaritionSlice from '../features/productVaritionSlice';
import fetchSocialMedia from '../features/socialMediaSlice';
import postRatingSlice from '../features/UserRatingPost';
import getRatingSlice from '../features/UserRatingGet';
import menuCategorySlice from '../features/menuCategories';
import defaultCategorySlice from '../features/defaultCategories';
import mainCategorySlice from '../features/mainCategories';
import subCategorySlice from '../features/getSubCategories';
import subSubCategorySlice from '../features/getSubSubCategories';
import getBrandsImageSlice from '../features/getBrandsImages';
import homePagesSlice from '../features/homePages';
import colorFilterSlice from '../features/colorsFilter';
import sizeFilterSlice from '../features/sizesFilter';
import getCartSlice from '../features/getCartData';
import addressOrderSlice from '../features/postOrderAddress';
import getShippingAddresSlice from '../features/getShippingAddress';
import cardPaymentSlice from '../features/paymentImplemention';
import emailOTPSlice from '../features/EmailOTPLogin';
import otpVerificationSlice from '../features/otpVerificationSlice';
import globalSearchSlice from '../features/globalSearch';
import mainPageCategoriesSlice from '../features/mainPageCategoriesSlice';
import mainPageSeoSlice  from '../features/mainPageSeo'

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['homePages', 'HomePageCateg'] 
};

const rootReducer = combineReducers({
  DetailPage: detailPageSlice,
  productVarition: productVaritionSlice,
  socialMedia: fetchSocialMedia,
  userRatings: postRatingSlice,
  getUserRatings: getRatingSlice,
  defaultCategory: defaultCategorySlice,
  categoryDetail: mainCategorySlice,
  menuCategory: menuCategorySlice,
  subCategoryDetail: subCategorySlice,
  subSubCategoryDetail: subSubCategorySlice,
  brandsImages: getBrandsImageSlice,
  homePages: homePagesSlice,
  colorFilter: colorFilterSlice,
  sizeFilter: sizeFilterSlice,
  getCart: getCartSlice,
  OrderAddress: addressOrderSlice,
  GetShippingAddress: getShippingAddresSlice,
  cardPayment: cardPaymentSlice,
  ByEmailOTP: emailOTPSlice,
  otpVerification: otpVerificationSlice,
  FenzacciSearch: globalSearchSlice,
  HomePageCateg: mainPageCategoriesSlice,
  MainPageSeo:mainPageSeoSlice
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: true, 
      serializableCheck: false
    }),
});

export const persistor = persistStore(store);
export default store;
